import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { EnvironmentService } from './environment.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private envSvc: EnvironmentService;
  constructor (injector:Injector) {
    this.envSvc = injector.get(EnvironmentService);
  }

  handleError(error: any): void {
    let environmentsToShowErrors = [
      'Local',
      'Staging',
      'QA',
    ];

    let env: string = this.envSvc.getEnvironment();

    if (environmentsToShowErrors.includes(env)) {
      console.error(error.stack.toString());
    }
    
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }

  }

}