import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LocalStorageService } from './services/localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  endpoints: any;

  private _showEnvironmentInUI$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private lsSvc: LocalStorageService
  ) {

    const apiEndpoints = {
      accounts: 'http://localhost:5004',
      catalog: 'http://localhost:5005',
      crm: 'http://localhost:5007',
      identity: 'http://localhost:5000',
      inventory: 'http://localhost:5002',
      merchandise: 'http://localhost:5006',
      monolith: 'http://localhost:5001',
      reporting: 'http://localhost:5008',
      sales: 'http://localhost:5003'
    };

    const qaEndpoints = {
      accounts: 'https://qa-myriad-accounts.pointcentric.app',
      catalog: 'https://qa-myriad-catalog.pointcentric.app',
      crm: 'https://qa-myriad-crm.pointcentric.app',
      identity: 'https://qa-myriad-identity.pointcentric.app',
      inventory: 'https://qa-myriad-inventory.pointcentric.app',
      merchandise: 'https://qa-myriad-merchandise.pointcentric.app',
      monolith: 'https://qa-myriad-monolith.pointcentric.app',
      sales: 'https://qa-myriad-sales.pointcentric.app',
      reporting: 'https://qa-myriad-reporting.pointcentric.app'
    };

    const trainingUIDomainName = 'trainingpointcentric';

    const trainingAPIDomainName = 'pointcentric';

    const hostname = document.location.hostname;

    this.endpoints = qaEndpoints;

    if (hostname !== 'localhost') {

      var [host, domain, tld] = hostname.split('.'),
        eps = {}; 
      // training environment setup
        if(domain.toLowerCase() == trainingUIDomainName){
          domain = trainingAPIDomainName;
          host = "training-" + host;
        }

      // Multi-tenancy
      Object.keys(apiEndpoints).forEach((ep) => {
        eps[ep] = `https://${host}-${ep}.${domain}.${tld}`;
      });

      this.endpoints = eps;

    } else {
      this.endpoints = apiEndpoints;
    }

  }

  get showEnvironmentInUI$(): Observable<boolean> { 
    return this._showEnvironmentInUI$.asObservable();
  }

  showEnv() {
    this.lsSvc.setItem('showEnv', true);
    this._showEnvironmentInUI$.next(true);
  }

  hideEnv() {
    this.lsSvc.setItem('showEnv', false);
    this._showEnvironmentInUI$.next(false);
  }

  getEnvironment(): string {

    let domain = {
      Local: 'localhost',
      Staging: 'staging-myriad',
      Training: 'trainingpointcentric',
      QA: 'qa-myriad'
    }

    let env: string;
    let url: string = window.location.href;
    if (url.includes(domain.Local)) {
      env = 'Local';
    }
    else if (url.includes(domain.Staging)) {
      env = 'Staging';
    }
    else if (url.includes(domain.Training)) {
      env = 'Training';
    }
    else if (url.includes(domain.QA)) {
      env = 'QA';
    }
    else {
      env = 'Production'
    }

    return env;
  }

}
