import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-record-dialog-mobile',
  templateUrl: './delete-record-dialog-mobile.component.html',
  styleUrls: ['./delete-record-dialog-mobile.component.scss']
})
export class DeleteRecordDialogMobileComponent implements OnInit {

  subject = 'item';

  constructor(
    public dialogRef: MatDialogRef<DeleteRecordDialogMobileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

  /**
   * Handle confirm button event
   */
   onConfirm(): void {
    
    this.dialogRef.close({
      confirm: true,
      id: this.data['id']
    });
  }

  /**
   * Handle cancel button event
   */
  onCancel(): void {

    this.dialogRef.close({
      confirm: false
    });
  }

  ngOnInit() {

    if (this.data['subject']) {
      this.subject = this.data['subject'];
    }
  }

}
