import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SIDE_BAR_MENU_ITEMS } from './side-bar-menu-items';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-side-bar-nav',
  templateUrl: './side-bar-nav.component.html',
  styleUrls: ['./side-bar-nav.component.scss']
})
export class SideBarNavComponent implements OnInit {

  panelOpenState: boolean = false;
  openItem: any;

  // menu items
  menuItems: any;
  subMenuItems: any;
  subMenuItems2: any;

  currentRoute: any;
  sidenavEl: any;

  slidePosition?: string | null;

  isMobile: boolean;

  constructor(
    private _authSvc: AuthService,
    private _router: Router
  ) { }

  ngOnInit(): void {

    if( /android(.+mobile)|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ){
      this.isMobile = true;
    }

    // get the side navigation element for animations
    this.sidenavEl = document.querySelector('#sidenav');

    this._prepareMenu();

    this._setActiveRoute();
  }

  /**
   * Prepare menu
   */
  private _prepareMenu(): void {

    const menu: any[] = [],
      items = SIDE_BAR_MENU_ITEMS;

    // loop through top level
    items.forEach((item: any) => {

      if (item.children) {

        const childMenu = this._getChildMenu(item);
        if (childMenu) {
          item.children = childMenu;
          menu.push(item);
        }

      } else {
        menu.push(item);
      }

    });

    this.menuItems = menu;

  }

  private _getChildMenu(item: any) {

    const items: any[] = [];
    item.children.forEach((child: any) => {

      if (child.children) {

        const cm = this._getChildMenu(child);
        if (cm) {
          child.children = cm;
          items.push(child);
        }

      } else if (child.claimProgram && (child.claimVerb && this._authSvc.hasClaim(child.claimProgram, child.claimVerb) || this._authSvc.hasClaim(child.claimProgram, 'View')) || !child.claimProgram) {
        if(this.isMobile && child.mobile){
          items.push(child);
        }
        else if(!this.isMobile && child.desktop){
          items.push(child);
        }
      }

    });

    return items.length > 0 ? items : null;

  }

  /**
   *  Show and highlight active route
   */
  private _setActiveRoute() {

    // get route to determine which menu item to open
    let routeItems = null;
    if (this._router.url) {

      routeItems = this._router.url.split('/');

      if (routeItems.length > 3) {

        // find parent route
        const parent: any = this.getItemByRoute(routeItems[1], this.menuItems);

        if (!parent) {
          console.warn('Unable to determine route for side menu');
          return;
        }

        this.currentRoute = parent['route'];

        // check if child route exists
        if (routeItems[2] && parent && parent['children']) {

          const child: any = this.getItemByRoute(`${routeItems[1]}/${routeItems[2]}`, parent['children']);

          if (child && child['children']) {

            this.subMenuItems = child['children'];

            if (routeItems[3]) {

              const grandChild: any = this.getItemByRoute(`${routeItems[1]}/${routeItems[2]}/${routeItems[3]}`, child['children']);

              if (grandChild && grandChild['children']) {
                this.subMenuItems2 = grandChild['children'];
                this.slidePosition = 'position-2';
              } else {
                this.slidePosition = 'position-1';
              }

            } else {
              this.slidePosition = 'position-1';
            }

          }

        }

      } else {
        this.currentRoute = routeItems[1];
      }

    }

    setTimeout(() => {

      const activeList = this.sidenavEl.querySelector('ul > li.active');
      if (activeList) {
        const dropMenu = activeList.querySelector('.drop-menu');
        dropMenu['style']['height'] = `${dropMenu['scrollHeight']}px`;
        this.openItem = {
          parent: activeList,
          dropMenu: dropMenu
        };
      }

    }, 500);

  }


  /**
   * Get menu item by route
   */
  getItemByRoute(route: string, items: any): object {

    const rte = items.filter((r: any) => {
      return r.route === route;
    });

    return rte[0];

  }

  /**
   * Close open menu item
   */
  closeSubmenu(e: any): void {

    e.preventDefault();

    if (!this.sidenavEl) {
      return;
    }

    const item = this.sidenavEl.querySelector('li.active');

    if (item) {
      const dropMenu = item.querySelector('.drop-menu');
      if (dropMenu) {
        dropMenu['style']['height'] = `${dropMenu['scrollHeight']}px`;
        setTimeout(() => {
          this.collapse(item, dropMenu);
        }, 50);
      }
    }

  }

  /**
   * Open drop menu
   * @param parent - parent element
   * @param dropMenu - dropmenu element
   */
  expand(parent: any, dropMenu: any): void {

    let height = 0;

    parent.classList.add('active');

    if (!dropMenu) {
      return;
    }

    dropMenu.classList.add('measure');

    setTimeout(() => {
      height = dropMenu['scrollHeight'];
      dropMenu.classList.remove('measure');
    }, 50);

    setTimeout(() => {
      dropMenu.classList.add('show');
      dropMenu['style']['height'] = `${height}px`;
    }, 100);

  }

  /**
   * Collapse dropmenu
   * @param parent - parent element
   * @param dropMenu - dropmenu element
   */
  collapse(parent: any, dropMenu: any): void {

    parent.classList.remove('active');
    if (!dropMenu) {
      return;
    }
    dropMenu['style']['height'] = `0px`;
    setTimeout(() => {
      dropMenu.classList.remove('show');
      dropMenu.setAttribute('style', '');
    }, 250);

  }

  /**
   * Open/close menu
   * @param collapse
   */
  toggleMenu(e: any): void {

    let parent = null;

    if (e.target.nodeName === 'I') {
      parent = e.target.parentElement.parentElement;
    } else {
      parent = e.target.parentElement;
    }

    const dropMenu = parent.querySelector('.drop-menu');

    if (parent.classList.contains('active')) {
      this.collapse(parent, dropMenu);
    } else {

      const itemObj = {dropMenu, parent};

      if (this.openItem && this.openItem['parent'] !== itemObj['parent']) {
        this.collapse(this.openItem['parent'], this.openItem['dropMenu']);
      }

      this.openItem = {
        dropMenu,
        parent
      };
      this.expand(parent, dropMenu);

    }

  }

  /**
   * Open sliding submenu
   * @param item
   */
  openSubnav(item: any): void {

    if (!this.slidePosition) {

      if (item['children']) {
        this.subMenuItems = item['children'];
        this.slidePosition = 'position-1';
      }

    } else {

      if (item['children']) {
        this.subMenuItems2 = item['children'];
        this.slidePosition = 'position-2';
      }

    }

  }

  /**
   * Close sliding submenu
   */
  closeSubnav(): void {

    if (this.slidePosition === 'position-2') {
      this.slidePosition = 'position-1';
      setTimeout(() => {
        this.subMenuItems2 = null;
      }, 250);
    } else {
      this.slidePosition = null;
      setTimeout(() => {
        this.subMenuItems = null;
      }, 250);
    }

  }

}
