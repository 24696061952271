import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

export interface CanComponentDeactivate {
 canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()

export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {

    canDeactivate(component: any, _route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {

        if (component && component['unsavedChanges']) {

            if (component['unsavedChanges']()) {
                const res = confirm('There are unsaved changes. Are you sure you want to proceed?');
                return res;
            } else {
                return true;
            }

        } else {
            return true;
        }

    }

}
