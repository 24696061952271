import { Component, OnInit } from '@angular/core';
import { UnsavedChangesDialogService } from './unsaved-changes-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { UnsavedChangesDialogContentComponent } from './unsaved-changes-dialog-content/unsaved-changes-dialog-content.component';

@Component({
  selector: 'app-unsaved-changes-dialog',
  templateUrl: './unsaved-changes-dialog.component.html',
  styleUrls: ['./unsaved-changes-dialog.component.scss']
})
export class UnsavedChangesDialogComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private _unsavedChangesDialogSvc: UnsavedChangesDialogService
  ) { }

  ngOnInit() {

    this._unsavedChangesDialogSvc.openDialog.subscribe(() => {
      this.openDialog();
    });

  }

  /**
   * Open unsaved changes dialog
   */
  openDialog(): void {

    const dialogRef = this.dialog.open(UnsavedChangesDialogContentComponent, {
      position: {top: '60px'},
      width: '500px',
      disableClose: true,
      autoFocus: false,
      restoreFocus: false
    });

    dialogRef.beforeClosed().subscribe((res: any) => {
      this._unsavedChangesDialogSvc.action(res);
    });

  }

}
