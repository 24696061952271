import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasClaimDirective, HasClaimEditFormDirective } from './has-claim.directive';

@NgModule({
  declarations: [HasClaimDirective, HasClaimEditFormDirective],
  exports: [HasClaimDirective, HasClaimEditFormDirective],
  imports: [
    CommonModule
  ]
})
export class HasClaimModule { }
