import { Directive, ViewContainerRef, TemplateRef, Input, ElementRef } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Directive({
  selector: '[hasClaim]'
})
export class HasClaimDirective {

  @Input() set hasClaim(claim: any) {

    let hasPermission = false;

    if (claim.overrideClaims) {
      hasPermission = true;
    } else if (typeof claim.verb === 'object') {
      claim.verb.forEach((verb: string) => {
        if (this.authSvc.hasClaim(claim.program, verb)) {
          hasPermission = true;
        }
      });
    } else {
      hasPermission = this.authSvc.hasClaim(claim.program, claim.verb);
    }

    if (hasPermission) {
      // Add template to DOM
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      // Remove template from DOM
      this.viewContainer.clear();
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authSvc: AuthService
  ) { }

}

@Directive({
  selector: '[hasClaimEditForm]'
})
export class HasClaimEditFormDirective {

  @Input() set hasClaimEditForm(claim: any) {

    let hasPermission = false;

    if (typeof claim.verb === 'object') {
      claim.verb.forEach((verb: string) => {
        if (this.authSvc.hasClaim(claim.program, verb)) {
          hasPermission = true;
        }
      });
    } else {
      hasPermission = this.authSvc.hasClaim(claim.program, claim.verb);
    }

    if (!hasPermission) {
      this.el.nativeElement.classList.add('read-only');
      this.el.nativeElement.setAttribute('disabled', true);
    }
  }

  constructor(
    public el: ElementRef,
    private authSvc: AuthService
  ) {}

}
