import { Component, OnInit } from '@angular/core';
import { ToastMessageService } from './toast-message.service';

@Component({
  selector: 'app-toast-message',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss']
})
export class ToastMessageComponent implements OnInit {

  msg = {
    type: null,
    message: null,
    icon: null,
    position: null,
    duration:null,
  };

  timeout: any = null;

  className: any = null;

  constructor(
    private toastMsgSvc: ToastMessageService
  ) { }

  /**
   * Component Init
   */
  ngOnInit() {

    // subscribe to toast message broadcast
    this.toastMsgSvc.broadcast
      .subscribe((res) => {
        this.show( res );
      });

  }

  /**
   * Show toast message
   * @param {object} msg
   */
  show(msg: any): void {

    this.reset();

    // update the view data
    this.msg = {
      type: msg['type'] || null,
      message: msg['message'] || null,
      icon: msg['icon'] || null,
      position: msg['position'] || 'top',
      duration: msg['duration'] || null,
    };

    // add class
    this.className = `${this.msg['type']} ${this.msg['position']}`;
    setTimeout(() => {
      this.className += ' open';
    }, 100);

    // determine how long to display notification
    const duration = msg['duration'] || 5000;

    this.timeout = setTimeout(() => {
      this.hide();
    }, duration);

  }

  /**
   * Hide notification
   */
  hide(): void {

    // remove the class "open" to hide the notification but not change the bg color
    this.className = `${this.msg['type']} ${this.msg['position']}`;
    setTimeout(() => {
      this.reset();
    }, 250);

  }

  /**
   * Reset data
   */
  reset(): void {

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.className = null;
    this.msg = {
      type: null,
      message: null,
      icon: null,
      position: null,
      duration:null
    };

  }

}
