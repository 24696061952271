import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-unsaved-changes-dialog-content',
  templateUrl: './unsaved-changes-dialog-content.component.html',
  styleUrls: ['./unsaved-changes-dialog-content.component.scss']
})
export class UnsavedChangesDialogContentComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<UnsavedChangesDialogContentComponent>
    // @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  onConfirm() {

    this.dialogRef.close({
      confirm: true
    });

  }

  onCancel(): void {
    this.dialogRef.close({
      confirm: false
    });
  }

}
