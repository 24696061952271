import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { LocalStorageService } from './localstorage.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private authSvc: AuthService,
        private router: Router,
        private lsSvc: LocalStorageService
    ) {}

    /**
     * Determine if user can activate route
     * If they can't, redirect to login
     * @returns {boolean}
     */
    canActivate(
        route: ActivatedRouteSnapshot,
        _state: RouterStateSnapshot
    ): boolean {

        if (!route.data) {
            console.warn('Route has no data');
        }

        // if user logs back in on another tab, we reset the expiration timer on this session (this tab)
        let expirationDue = Number(this.lsSvc.getItem('sessionExpiration'));
        if ((this.authSvc.expirationDueTime !== expirationDue) && this.authSvc.sessionTimerSub) {
            this.authSvc.sessionTimerSub.unsubscribe();
            this.authSvc.handleSessionExpiration();
        }

        // check that id is a number
        if (route.params.id) {
            const num = Number(route.params.id);
            if (isNaN(num)) {
                this.router.navigate(['/dashboard']);
                return false;
            }
        }

        // check if user is logged in
        const isLoggedIn = this.authSvc.isLoggedIn();
        if (!isLoggedIn) {
            this.router.navigate(['/']);
            return false;
        }

        // check if user has access based on claim
        if (route.data.claimProgram && route.data.claimVerb) {

            const hasClaim = this.authSvc.hasClaim(route.data.claimProgram, route.data.claimVerb);
            if (route.data && route.data.claimProgram) {
                if (hasClaim) {
                    return true;
                } else {
                    this.router.navigate(['/dashboard']);
                    return false;
                }
            }

            return true;

        }

        return true;

    }

}
