import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastMessageService {

  broadcast: EventEmitter<any> = new EventEmitter();

  constructor() { }

  public send(msg: any) {
    this.broadcast.emit(msg);
  }

}
