export const SIDE_BAR_MENU_ITEMS = [
    {
        id: 'menu-dashboard',
        label: 'Dashboard',
        icon: 'home',
        route: 'dashboard',
        claimProgram: null
    }, {
        id: 'menu-accounting',
        label: 'Accounting',
        icon: 'menu_book',
        route: 'accounting',
        children: [
            {
                id: 'menu-accounts-payable',
                label: 'Accounts Payable',
                route: 'accounting/accounts-payable',
                children: [
                    {
                        id: 'menu-invoices-chargebacks',
                        label: 'Invoices/Charge Backs',
                        route: 'accounting/accounts-payable/invoices-charge-backs',
                        claimProgram: 'APInvoiceChargebacks',
                        mobile: false, desktop: true
                    }, {
                        id: 'menu-payable-disbursements',
                        label: 'Payable Disbursements',
                        route: 'accounting/accounts-payable/payable-disbursements',
                        claimProgram: 'PayableDisbursements',
                        mobile: false, desktop: true
                    }, {
                        id: 'menu-payments',
                        label: 'Payments',
                        route: 'accounting/accounts-payable/payments',
                        claimProgram: 'APPayments',
                        mobile: false, desktop: true
                    }
                ]
            }, {
                id: 'menu-banking',
                label: 'Banking',
                route: 'accounting/banking',
                children: [
                    {
                        id: 'menu-bank-register',
                        label: 'Bank Register',
                        route: 'accounting/banking/bank-register',
                        claimProgram: 'BankRegister',
                        mobile: false, desktop: true
                    },
                    {
                        id: 'menu-bank-transaction',
                        label: 'Bank Transaction',
                        route: 'accounting/banking/bank-transaction',
                        claimProgram: 'BankTransaction',
                        mobile: false, desktop: true
                    }
                ]
            }, {
                id: 'menu-general-ledger',
                label: 'General Ledger',
                route: 'accounting/general-ledger',
                children: [
                    {
                        id: 'menu-chart-of-accounts',
                        label: 'Chart of Accounts',
                        route: 'accounting/general-ledger/chart-of-accounts',
                        claimProgram: 'ChartOfAccounts',
                        mobile: false, desktop: true
                    },{
                        id: 'menu-close-month',
                        label: 'Close Month',
                        route: 'accounting/general-ledger/close-month',   
                        claimProgram: 'CloseMonth',
                        mobile: true, desktop: true
                    },{
                        id: 'menu-financial-statements',
                        label: 'Financial Statements',
                        route: 'accounting/general-ledger/financial-statements',
                        claimProgram: 'FinancialStatementConfig',
                        mobile: false, desktop: true
                    }, {
                        id: 'menu-end-of-year-processing',
                        label: 'End of Year Processing',
                        route: 'accounting/general-ledger/end-of-year-processing',
                        claimProgram: 'EndOfYearProcessing',
                        mobile: false, desktop: true
                    }, {
                        id: 'menu-fiscal-years',
                        label: 'Fiscal Years',
                        route: 'accounting/general-ledger/fiscal-years',
                        claimProgram: 'GLFiscalYears',
                        mobile: false, desktop: true
                    }, {
                        id: 'menu-journal-entry',
                        label: 'Journal Entry',
                        route: 'accounting/general-ledger/journal-entry',
                        claimProgram: 'GLJournalEntry',
                        mobile: false, desktop: true
                    }, {
                        id: 'menu-sales-gl-configuration',
                        label: 'Sales G/L Configuration',
                        route: 'accounting/general-ledger/sales-gl-configuration',
                        claimProgram: 'SalesGLConfiguration',
                        mobile: false, desktop: true
                    }, {
                        id: 'menu-sales-posting-update',
                        label: 'Sales Posting Update',
                        route: 'accounting/general-ledger/sales-posting-update',
                        claimProgram: 'SalesPostingUpdate',
                        mobile: false, desktop: true
                    }
                ]
            }, {
                id: 'menu-accounting-reports',
                label: 'Reports',
                route: 'accounting/reports',
                children: [
                    {
                        id: 'menu-accounts-payable-report',
                        label: 'Accounts Payable Report',
                        route: 'accounting/reports/accounts-payable',
                        claimProgram: 'AccountsPayableReport',
                        mobile: false, desktop: true
                    }, {
                        id: 'menu-balance-sheet',
                        label: 'Balance Sheet',
                        route: 'accounting/reports/financial-statement-balance-sheet',
                        claimProgram: 'BalanceSheetReport',
                        mobile: false, desktop: true
                    }, {
                        id: 'menu-bank-account-report',
                        label: 'Bank Account Report',
                        route: 'accounting/reports/bank-account',
                        claimProgram: 'BankAccountReport',
                        mobile: false, desktop: true
                    }, {
                        id: 'menu-bank-reconciliation-report',
                        label: 'Bank Reconciliaton Report',
                        route: 'accounting/reports/bank-reconciliation',
                        claimProgram: 'BankReconciliationReport',
                        mobile: false, desktop: true
                    }, {
                        id: 'menu-chart-of-accounts-report',
                        label: 'Chart of Accounts Report',
                        route: 'accounting/reports/chart-of-accounts',
                        claimProgram: 'ChartOfAccountReport',
                        mobile: false, desktop: true
                    }, {
                        id: 'menu-income-statement',
                        label: 'Income Statement',
                        route: 'accounting/reports/financial-statement-income-statement',
                        claimProgram: 'IncomeStatementReport',
                        mobile: false, desktop: true
                    }, {
                        id: 'menu-journal-report',
                        label: 'Journal Report',
                        route: 'accounting/reports/journal',
                        claimProgram: 'JournalReport',
                        mobile: false, desktop: true
                    }, {
                        id: 'menu-ledger-report',
                        label: 'Ledger Report',
                        route: 'accounting/reports/ledger',
                        claimProgram: 'GeneralLedgerReport',
                        mobile: false, desktop: true
                    }, {
                        id: 'menu-payables-disbursement-report',
                        label: 'Payables Disbursement Report',
                        route: 'accounting/reports/payables-disbursement',
                        claimProgram: 'PayableDisbursementReport',
                        mobile: false, desktop: true
                    }, {
                        id: 'menu-purchase-journal-report',
                        label: 'Purchase Journal Report',
                        route: 'accounting/reports/purchase-journal',
                        claimProgram: 'PurchaseJournalReport',
                        mobile: false, desktop: true
                    }, {
                        id: 'menu-trial-balance-report',
                        label: 'Trial Balance Report',
                        route: 'accounting/reports/trial-balance',
                        claimProgram: 'TrialBalanceReportGet',
                        mobile: false, desktop: true
                    }

                ]
            }
        ]
    }, {
        id: 'menu-accounts-receivable',
        label: 'Accounts Receivable',
        route: 'accounts',
        icon: 'inbox',
        children: [
            {
                id: 'menu-ar-transactions',
                label: 'A/R Transactions',
                route: 'accounts/ar-transactions',
                claimProgram: 'ARTransactions',
                mobile: true, desktop: true
            }, {
                id: 'menu-accounts-receivable-reports',
                label: 'Reports',
                route: 'accounts/reports',
                children: [
                    {
                        label: 'Customer Account Report',
                        route: 'accounts/reports/customer-account',
                        claimProgram: 'CustomerAccountReport',
                        mobile: true, desktop: true
                    }, {
                        label: 'Customer Statements',
                        route: 'accounts/reports/customer-statements',
                        claimProgram: 'CustomerAccountStatement',
                        mobile: true, desktop: true
                    }
                ]
            }
        ]
    }, {
        id: 'menu-crm',
        label: 'CRM',
        route: 'crm',
        icon: 'groups',
        children: [
            {
                id: 'menu-crm-upboard',
                label: 'Upboard',
                route: 'crm/upboard',
                claimProgram: 'CrmUpboard',
                mobile: true, desktop: true
            }, {
                id: 'menu-crm-opportunities',
                label: 'Opportunities',
                route: 'crm/opportunities',
                claimProgram: 'CrmUpboard',
                mobile: true, desktop: true
            }
        ]
    }, {
        id: 'menu-balancing',
        label: 'Balancing',
        route: 'balancing',
        icon: 'account_balance',
        children: [
            {
                id: 'menu-cash-tran-auto-deposit',
                label: 'Cash Transaction - Auto Deposit',
                route: 'balancing/cash-tran-auto-deposit',
                claimProgram: 'CashTranAutoDeposit',
                mobile: true, desktop: true
            },
            {
                id: 'menu-store-close-day',
                label: 'Close Day',
                route: 'balancing/close-day',   
                claimProgram: 'CloseDay',            
                mobile: true, desktop: true
            },
            {
                id: 'menu-balancing-reports',
                label: 'Reports',
                route: 'balancing/reports',
                children: [
                    {
                        id: 'menu-ar-transaction-report',
                        label: 'AR Transaction Report',
                        route: 'balancing/reports/ar-transaction',
                        claimProgram: 'ARTransactionReport',
                        mobile: true, desktop: true
                    }, {
                        id: 'menu-cash-transaction-report',
                        label: 'Cash Transaction Report',
                        route: 'balancing/reports/cash-transaction',
                        claimProgram: 'CashTransactionReport',
                        mobile: true, desktop: true
                    }, {
                        id: 'menu-written-delivered-business-report',
                        label: 'Written Delivered Business Report',
                        route: 'balancing/reports/written-delivered-business',
                        claimProgram: 'WrittenDeliveredBusinessReport',
                        mobile: true, desktop: true
                    }
                ]
            }
        ]
    }, {
        id: 'menu-bacoding',
        label: 'Barcoding',
        icon: 'speaker_phone',
        route: 'barcoding',
        children: [
            {
                id: 'menu-barcode-cycle-inventory',
                label: "Barcoding Cycle Inventory",
                route: 'inventory/cycle-inventory-mobile',
                claimProgram: 'BarcodeCycleInventory',
                mobile: true
            }, {
                id: 'menu-mobile-inventory-lookup',
                label: 'Barcode Inventory Lookup',
                route: 'inventory/lookup-mobile',
                claimProgram: 'BarcodeInventoryLookup',
                mobile: true, desktop: false
            }, {
                id: 'menu-mobile-inventory-receivings',
                label: 'Barcode Receivings',
                route: 'inventory/receivings-mobile',
                children: [
                    {
                        id: 'menu-mobile-receive-into-1',
                        label: 'Receive into 1 Location',
                        route: 'inventory/receivings-mobile/receive-into-one-location/1',
                        claimProgram: 'BarcodeReceiving',
                        mobile: true, desktop: false
                    }, {
                        id: 'menu-mobile-receive-into-multi',
                        label: 'Receive into Multi Locations',
                        route: 'inventory/receivings-mobile/receive-into-one-location/2',
                        claimProgram: 'BarcodeReceiving',
                        mobile: true, desktop: false
                    }
                ]

            }, {
                id: 'menu-mobile-inventory-transfers',
                label: 'Barcode Transfers',
                route: 'inventory/transfers-mobile',
                claimProgram: 'BarcodeTransfer',
                mobile: true, desktop: false
            } 
            
        ]
    }, {
        id: 'menu-delivery',
        label: 'Delivery',
        route: 'delivery',
        icon: 'local_shipping',
        children: [
            {
                id: 'menu-delivery-calendars',
                label: 'Delivery Calendars',
                route: 'delivery/delivery-calendars',
                claimProgram: 'DeliveryCalendar',
                mobile: true, desktop: true
            }, {
                id: 'menu-delivery-zones',
                label: 'Delivery Zones',
                route: 'delivery/delivery-zones',
                claimProgram: 'DeliveryZone',
                mobile: true, desktop: true
            }, {
                id: 'menu-scheduled-deliveries',
                label: 'Scheduled Deliveries',
                route: 'delivery/scheduled-deliveries',
                claimProgram: 'ScheduledDeliveries',
                mobile: true, desktop: true
            }, {
                id: 'menu-trucks',
                label: 'Trucks',
                route: 'delivery/trucks',
                claimProgram: 'Trucks',
                mobile: true, desktop: true
            }, {
                id: 'menu-truck-routing',
                label: 'Truck Routing',
                route: 'delivery/truck-routing',
                claimProgram: 'TruckRoutes',
                mobile: true, desktop: true
            }, {
                id: 'menu-delivery-reports',
                label: 'Reports',
                route: 'delivery/reports',
                children: [
                    {
                        id: 'menu-delivery-calendars',
                        label: 'Delivery Calendar Report',
                        route: 'delivery/reports/delivery-calendar',
                        claimProgram: 'DeliveryCalendarReport',
                        mobile: true, desktop: true
                    }, {
                        id: 'menu-dispatch-track-export',
                        label: 'Dispatch Track Export',
                        route: 'delivery/reports/dispatch-track-export',
                        claimProgram: 'DispatchTrackExport',
                        claimVerb: 'Export',
                        mobile: true, desktop: true
                    }, {
                        id: 'menu-truck-stop-report',
                        label: 'Truck Stop Report',
                        route: 'delivery/reports/truck-stop',
                        claimProgram: 'TruckStopReport',
                        mobile: true, desktop: true
                    }
                ]
            }
        ]
    }, {
        id: 'menu-inventory',
        label: 'Inventory',
        route: 'inventory',
        icon: 'import_contacts',
        children: [
            {
                id: 'menu-inventory-add-subtract',
                label: 'Add/Subtract',
                route: 'inventory/adjustments',
                claimProgram: 'InvAddSubtractAdjustments',
                mobile: true, desktop: true
            }, {
                id: 'menu-inventory-cost-freight-adjustments',
                label: 'Cost/Freight Adjustments',
                route: 'inventory/cost-freight-adjustments',
                claimProgram: 'InvCostFreightAdjustments',
                mobile: true, desktop: true
            }, {
                id: 'menu-cycle-inventory',
                label: "Cycle Inventory",
                route: 'inventory/cycle-inventory',
                claimProgram: 'CycleInventory',
                mobile: false, desktop: true
            }, {
                id: 'menu-inventory-labels-tags',
                label: 'Labels/Tags',
                route: 'labels-tags',
                children: [
                    {
                        id: 'menu-labels-tags-frame-models',
                        label: 'Frame Models',
                        route: 'inventory/labels-tags/frame-models',
                        claimProgram: 'InventoryLabelsTagsFrames',
                        mobile: true, desktop: true
                    }, {
                        id: 'menu-labels-tags-on-hand-quantities',
                        label: 'On Hand Quantities',
                        route: 'inventory/labels-tags/on-hand-quantities',
                        claimProgram: 'InventoryLabelsTags',
                        mobile: true, desktop: true
                    }, {
                        id: 'menu-labels-tags-group-model',
                        label: 'Group Labels',
                        route: 'inventory/labels-tags/group-models',
                        claimProgram: 'GroupModelTags',
                        mobile: true, desktop: true
                    }, {
                        id: 'menu-labels-tags-locations',
                        label: 'Locations',
                        route: 'inventory/labels-tags/locations',
                        claimProgram: 'InventoryLabelsTags',
                        mobile: true, desktop: true
                    }
                ]
            }, {
                id: 'menu-inventory-lookup',
                label: 'Inventory Lookup',
                route: 'inventory/lookup',
                claimProgram: 'InventoryLookup',
                mobile: true, desktop: true
            }, {
                id: 'menu-inventory-receivings',
                label: 'Receivings',
                route: 'inventory/receivings',
                claimProgram: 'InventoryReceivings',
                mobile: true, desktop: true
            }, {
                id: 'menu-inventory-transfers',
                label: 'Transfers',
                route: 'inventory/transfers',
                claimProgram: 'InventoryTransfers',
                mobile: true, desktop: true
            }, {
                id: 'menu-inventory-allocation',
                label: 'Inventory Allocation',
                route: 'inventory/inventory-allocation',
                claimProgram: 'InventoryAllocation',
                mobile: true, desktop: true
            }, {
                id: 'menu-inventory-reports',
                label: 'Reports',
                route: 'inventory/reports',
                children: [
                    {
                        id: 'menu-inventory-cost-report',
                        label: 'Inventory Cost Report',
                        route: 'inventory/reports/inventory-cost',
                        claimProgram: 'InventoryCostReport',
                        mobile: true, desktop: true
                    }, {
                        id: 'menu-inventory-picking-report',
                        label: 'Inventory Picking Report',
                        route: 'inventory/reports/inventory-picking',
                        claimProgram: 'InventoryPickingReport',
                        mobile: true, desktop: true
                    }, {
                        id: 'menu-inventory-receiving-report',
                        label: 'Inventory Receiving Report',
                        route: 'inventory/reports/inventory-receiving',
                        claimProgram: 'InventoryReceivingReport',
                        mobile: true, desktop: true
                    }, {
                        id: 'menu-inventory-receiving-worksheet',
                        label: 'Inventory Receiving Worksheet',
                        route: 'inventory/reports/inventory-receiving-worksheet',
                        claimProgram: 'InventoryReceivingWorksheet',
                        mobile: true, desktop: true
                    }, {
                        id: 'menu-inventory-status-report',
                        label: 'Inventory Status Report',
                        route: 'inventory/reports/inventory-status',
                        claimProgram: 'InventoryStatusReport',
                        mobile: true, desktop: true
                    }, {
                        id: 'menu-inventory-transaction-report',
                        label: 'Inventory Transaction Report',
                        route: 'inventory/reports/inventory-transaction',
                        claimProgram: 'InventoryTransactionReport',
                        mobile: true, desktop: true
                    }, {
                        id: 'menu-inventory-transfer-report',
                        label: 'Inventory Transfer Report',
                        route: 'inventory/reports/inventory-transfer',
                        claimProgram: 'InventoryTransferReport',
                        mobile: true, desktop: true
                    }, {
                        id: 'menu-physical-inventory-report',
                        label: 'Physical Inventory Report',
                        route: 'inventory/reports/physical-inventory',
                        claimProgram: 'PhysicalInventoryReport',
                        mobile: true, desktop: true
                    }
                ]
            }
        ]
    }, {
        id: 'menu-merchandising',
        label: 'Merchandising',
        route: 'merchandising',
        icon: 'layers',
        children: [
            {
                id: 'menu-custom-items',
                label: 'Custom Item Groups',
                route: 'merchandising/custom-items',
                claimProgram: 'CustomItems',
                mobile: true, desktop: true
            }, {
                id: 'menu-departments',
                label: 'Departments',
                route: 'merchandising/departments',
                claimProgram: 'Departments',
                mobile: true, desktop: true
            }, {
                id: 'menu-group-models',
                label: 'Group Models',
                route: 'merchandising/groupmodels',
                claimProgram: 'GroupModels',
                mobile: true, desktop: true
            }, {
                id: 'menu-models',
                label: 'Models',
                route: 'merchandising/models',
                claimProgram: 'Models',
                mobile: true, desktop: true
            }, {
                id: 'menu-model-bulk-update',
                label: 'Models Bulk Update',
                route: 'merchandising/model-bulk-update',
                claimProgram: 'ModelBulkUpdate',
                mobile: true, desktop: true
            }, {
                id: 'menu-catalog-import',
                label: 'Catalog Import',
                route: 'merchandising/catalog-import',
                claimProgram: 'CatalogImport',
                mobile: true, desktop: true
            }, {
                id: 'menu-purchase-orders',
                label: 'Purchase Orders',
                route: 'merchandising/purchase-orders',
                claimProgram: 'PurchaseOrders',
                mobile: true, desktop: true
            }, {
                id: 'menu-purchase-orders-cbos',
                label: 'Purchase Orders CBOs',
                route: 'merchandising/customer-back-orders',
                claimProgram: 'CustomerBackOrder',
                mobile: true, desktop: true
            }, {
                id: 'menu-purchase-orders-min-max',
                label: 'Purchase Orders Min/Max',
                route: 'merchandising/purchase-orders-min-max',
                claimProgram: 'PoMinMax',
                mobile: true, desktop: true
            }, {
                id: 'menu-vendors',
                label: 'Vendors',
                route: 'merchandising/vendors',
                claimProgram: 'Vendors',
                mobile: true, desktop: true
            }, {
                id: 'menu-merchandising-reports',
                label: 'Reports',
                route: 'merchandising/reports',
                children: [
                    {
                        id: 'menu-department-report',
                        label: 'Department Report',
                        route: 'merchandising/reports/department',
                        claimProgram: 'DepartmentReportGet', 
                        mobile: true, desktop: true  
                    },
                    {
                        id: 'menu-merchandise-managers-report',
                        label: 'Merchandise Managers Report',
                        route: 'merchandising/reports/merchandisemanagers',
                        children: [
                            {
                                id: 'menu-merchandise-managers-regular-report',
                                label: 'Regular Report',
                                route: 'merchandising/reports/merchandise-managers',
                                claimProgram: 'MerchandiseManagersReport',
                                mobile: true, desktop: true
                            }, {
                                id: 'menu-merchandise-managers-unsampled-report',
                                label: 'Un-Sampled Report',
                                route: 'merchandising/reports/merchandise-managers-unsampled',
                                claimProgram: 'MerchandiseManagersReport',
                                mobile: true, desktop: true
                            }, {
                                id: 'menu-merchandise-managers-best-sellers-report',
                                label: 'Best Sellers Report',
                                route: 'merchandising/reports/merchandise-managers-best-sellers',
                                claimProgram: 'MerchandiseManagersReport',
                                mobile: true, desktop: true
                            }
                        ]
                    },
                    {
                        id: 'menu-purchase-order-reports',
                        label: 'Purchase Order Report',
                        route: 'merchandising/reports/purchase-order',
                        claimProgram: 'PurchaseOrderReport',
                        mobile: true, desktop: true
                    },
                    {
                        id: 'menu-group-model-report',
                        label: 'Group Model Report',
                        route: 'merchandising/reports/group-model',
                        claimProgram: 'GroupModelReport',
                        mobile: true, desktop: true
                    }
                ]
            }
        ]
    }, {
        id: 'menu-sales',
        label: 'Sales',
        route: 'sales',
        icon: 'shopping_cart',
        children: [
            {
                id: 'menu-carts',
                label: 'Carts',
                route: 'carts',
                claimProgram: 'Carts',
                mobile: true, desktop: true
            }, {
                id: 'menu-customer-accounts',
                label: 'Customer Accounts',
                route: 'sales/customer-accounts',
                claimProgram: 'CustomerAccounts',
                mobile: true, desktop: true
            }, {
                id: 'menu-commission-adjustments',
                label: 'Commission Adjustments',
                route: 'sales/commission-adjustments',
                claimProgram: 'CommissionAdjustments',
                mobile: true, desktop: true
            }, {
                id: 'menu-returns',
                label: 'Returns',
                route: 'sales/returns',
                claimProgram: 'Returns',
                mobile: true, desktop: true
            }, {
                id: 'menu-sales-adustments',
                label: 'Sales Adjustments',
                route: 'sales/adjustments',
                claimProgram: 'SalesAdjustments',
                mobile: true, desktop: true
            }, {
                id: 'menu-sales-orders',
                label: 'Sales Orders',
                route: 'sales/orders',
                claimProgram: 'SalesOrders',
                mobile: true, desktop: true
            }, {
                id: 'menu-service-orders',
                label: 'Service Orders',
                route: 'sales/service-orders',
                claimProgram: 'ServiceOrders',
                mobile: true, desktop: true
            }, {
                id: 'menu-sales-reports',
                label: 'Reports',
                route: 'sales/reports',
                children: [
                    {
                        id: 'menu-commission-report',
                        label: 'Commission Report',
                        route: 'sales/reports/commission',
                        claimProgram: 'CommissionReport',
                        mobile: true, desktop: true
                    },
                    {
                        id: 'menu-demographics-report',
                        label: 'Demographics Report',
                        route: 'sales/reports/demographics',
                        claimProgram: 'DemographicsAnalysisReport',
                        mobile: true, desktop: true
                    }, {
                        id: 'menu-open-sales-report',
                        label: 'Open Sales Report',
                        route: 'sales/reports/open-sales',
                        claimProgram: 'OpenSalesReportGet',
                        mobile: true, desktop: true
                    }, {
                        id: 'menu-sales-analysis-report',
                        label: 'Sales Analysis Report',
                        route: 'sales/reports/sales-analysis',
                        claimProgram: 'SalesAnalysisReport',
                        mobile: true, desktop: true
                    }, {
                        id: 'menu-sales-order-report',
                        label: 'Sales Order Report',
                        route: 'sales/reports/sales-order',
                        claimProgram: 'SalesOrderReport',
                        mobile: true, desktop: true
                    }, {
                        id: 'menu-sales-tax-report',
                        label: 'Sales Tax Report',
                        route: 'sales/reports/sales-tax',
                        claimProgram: 'SalesTaxReport',
                        mobile: true, desktop: true
                    }, {
                        id: 'menu-service-order-report',
                        label: 'Service Order Report',
                        route: 'sales/reports/service-order',
                        claimProgram: 'ServiceOrderReportGet',
                        mobile: true, desktop: true
                    }
                ]
            }
        ]
    }, {
        id: 'menu-configuration',
        label: 'Configuration',
        route: 'configuration',
        icon: 'settings',
        children: [
            {
                id: 'menu-codes',
                label: 'Codes',
                route: 'configuration/codes',
                children: [
                    {
                        id: 'menu-codes-additional-services',
                        label: 'Additional Services',
                        route: 'configuration/codes/additional-services',
                        claimProgram: 'CodesAdditionalServices',
                        mobile: true, desktop: true
                    }, {
                        id: 'codes-advertising',
                        label: 'Advertising',
                        route: 'configuration/codes/advertising',
                        claimProgram: 'CodesAdvertising',
                        mobile: true, desktop: true
                    }, {
                        id: 'menu-codes-ar-finance',
                        label: 'A/R Finance',
                        route: 'configuration/codes/ar-finance',
                        claimProgram: 'CodesARFinanceType',
                        mobile: true, desktop: true
                    }, {
                        id: 'menu-codes-ar-transaction',
                        label: 'A/R Transaction',
                        route: 'configuration/codes/ar-transactions',
                        claimProgram: 'CodesArTransaction',
                        mobile: true, desktop: true
                    }, {
                        id: 'menu-codes-chargebacks',
                        label: 'Chargebacks',
                        route: 'configuration/codes/chargebacks',
                        claimProgram: 'CodesChargeBacks',
                        mobile: true, desktop: true
                    }, {
                        id: 'menu-codes-crm',
                        label: 'CRM Code',
                        route: 'configuration/codes/crm',
                        children: [
                            {
                                id: 'codes-activity-type',
                                label: 'Activity Types',
                                route: 'configuration/codes/activity-types',
                                claimProgram: 'CodesActivityTypes',
                                mobile: true, desktop: true
                            },  {
                                id: 'menu-codes-decline-reasons',
                                label: 'Decline Reasons',
                                route: 'configuration/codes/decline-reasons',
                                claimProgram: 'CodesDeclineReasons',
                                mobile: true, desktop: true
                            }, {
                                id: 'menu-codes-opportunity-type',
                                label: 'Opportunity Type',
                                route: 'configuration/codes/opportunity-types',
                                claimProgram: 'CodesOpportunityTypes',
                                mobile: true, desktop: true
                            }, {
                                id: 'menu-codes-opportunity-result',
                                label: 'Opportunity Result',
                                route: 'configuration/codes/opportunity-result',
                                claimProgram: 'CodesOpportunityResult',
                                mobile: true, desktop: true
                            }, 
                        ]
                    }, {
                        id: 'menu-codes-customer-follow-up',
                        label: 'Customer Follow Up',
                        route: 'configuration/codes/customer-follow-up',
                        claimProgram: 'CodesFollowUp',
                        mobile: true, desktop: true
                    }, {
                        id: 'menu-codes-inventory',
                        label: 'Inventory',
                        route: 'configuration/codes/inventory',
                        children: [
                            {
                                id: 'menu-codes-adjustments',
                                label: 'Adjustments',
                                route: 'configuration/codes/inventory/adjustments',
                                claimProgram: 'CodesInventoryAdjustments',
                                mobile: true, desktop: true
                            }, {
                                id: 'menu-codes-status',
                                label: 'Status',
                                route: 'configuration/codes/inventory/status',
                                claimProgram: 'CodesInventoryStatus',
                                mobile: true, desktop: true
                            }, {
                                id: 'menu-codes-transfer',
                                label: 'Transfer',
                                route: 'configuration/codes/inventory/transfer',
                                claimProgram: 'CodesInventoryTransfer',
                                mobile: true, desktop: true
                            }
                        ]
                    }, {
                        id: 'menu-codes-method-of-payment',
                        label: 'Method of Payment',
                        route: 'configuration/codes/method-of-payment',
                        claimProgram: 'CodesMethodOfPayment',
                        mobile: true, desktop: true
                    }, {
                        id: 'menu-codes-model',
                        label: 'Model',
                        route: 'configuration/codes/model',
                        children: [
                            {
                                id: 'menu-codes-cost',
                                label: 'Cost',
                                route: 'configuration/codes/model/cost-codes',
                                claimProgram: 'CodesModel',
                                mobile: true, desktop: true
                            }, {
                                id: 'menu-codes-discontinued',
                                label: 'Discontinued',
                                route: 'configuration/codes/model/discontinued-codes',
                                claimProgram: 'CodesModelPrice',
                                mobile: true, desktop: true
                            }, {
                                id: 'menu-codes-price',
                                label: 'Price',
                                route: 'configuration/codes/model/price-codes',
                                claimProgram: 'CodesModelPrice',
                                mobile: true, desktop: true
                            }
                        ]
                    }, {
                        id: 'menu-payable-hold',
                        label: 'Payable Hold',
                        route: 'configuration/codes/payable-hold',
                        claimProgram: 'CodesPayableHold',
                        mobile: true, desktop: true
                    }, {
                        id: 'menu-codes-purchase-order',
                        label: 'Purchase Order',
                        route: 'configuration/codes/purchase-order',
                        claimProgram: 'CodesPurchaseOrder',
                        mobile: true, desktop: true
                    }, {
                        id: 'menu-codes-purchase-order-discounts',
                        label: 'Purchase Order Discounts',
                        route: 'configuration/codes/purchase-order-discounts',
                        claimProgram: 'CodesPurchaseOrderDiscounts',
                        mobile: true, desktop: true
                    }, {
                        id: 'menu-codes-return-reasons',
                        label: 'Return Reasons',
                        route: 'configuration/codes/return-reasons',
                        claimProgram: 'CodesReturnReasons',
                        mobile: true, desktop: true
                    }, {
                        id: 'menu-codes-sales-persons',
                        label: 'Sales Persons',
                        route: 'configuration/codes/sales-persons',
                        claimProgram: 'CodesSalesPersons',
                        mobile: true, desktop: true
                    }, {
                        id: 'menu-codes-sales-order-discount',
                        label: 'Sales Order Discount Codes',
                        route: 'configuration/codes/sales-order-discount-codes',
                        claimProgram: 'CodesSalesOrderDiscount',
                        mobile: true, desktop: true
                    }, {
                        id: 'menu-codes-sales-order-referral-type',
                        label: 'Sales Order Referral Type Codes',
                        route: 'configuration/codes/sales-order-referral-type-codes',
                        claimProgram: 'CodesSalesOrderReferralType',
                        mobile: true, desktop: true
                    }, {
                        id: 'menu-codes-service-order',
                        label: 'Service Order Codes',
                        route: 'configuration/codes/service-order-codes',
                        claimProgram: 'CodesServiceOrder',
                        mobile: true, desktop: true
                    }, {
                        id: 'menu-codes-service-order-reason',
                        label: 'Service Order Reason Codes',
                        route: 'configuration/codes/service-order-reason-codes',
                        claimProgram: 'CodesServiceOrderReasons',
                        mobile: true, desktop: true
                    }
                ]
            }, {
                id: 'menu-commissions',
                label: 'Commissions',
                route: 'configuration/commissions',
                claimProgram: 'Commissions',
                mobile: true, desktop: true
            }, {
                id: 'menu-companies',
                label: 'Companies',
                route: 'configuration/companies',
                claimProgram: 'Company',
                mobile: true, desktop: true
            }, {
                id: 'menu-crm-configuration',
                label: 'CRM Configuration',
                route: 'configuration/crm-configuration',
                claimProgram: 'CrmConfiguration',
                mobile: true, desktop: true
            }, {
                id: 'menu-model-config',
                label: 'Model Configuration',
                route: 'configuration/model-config',
                claimProgram: 'ModelConfig',
                mobile: true, desktop: true
            }, {
                id: 'menu-roles',
                label: 'Roles',
                route: 'configuration/role-groups',
                claimProgram: 'Roles',
                mobile: true, desktop: true
            }, {
                id: 'menu-sales-persons',
                label: 'Sales Persons',
                route: 'configuration/sales-persons',
                claimProgram: 'Salespersons',
                mobile: true, desktop: true
            }, {
                id: 'menu-stores',
                label: 'Stores',
                route: 'configuration/stores',
                claimProgram: 'Stores',
                mobile: true, desktop: true
            }, {
                id: 'menu-taxes',
                label: 'Taxes',
                route: 'configuration/taxes',
                claimProgram: 'TaxMaintenance',
                mobile: true, desktop: true
            }, {
                id: 'menu-users',
                label: 'Users',
                route: 'configuration/users',
                claimProgram: 'Users',
                mobile: true, desktop: true
            }, {
                id: 'menu-zip-codes',
                label: 'Zip Codes',
                route: 'configuration/zip-codes',
                claimProgram: 'PostalCodes',
                mobile: true, desktop: true
            }
        ]
    }, {
        id: 'menu-admin',
        label: 'Admin',
        route: 'admin',
        icon: 'admin_panel_settings',
        children: [{
            id: 'menu-logged-in-users',
            label: 'Logged in Users',
            route: 'admin/logged-in-users',
            claimProgram: 'AdminLoggedInUsers',
            mobile: false, desktop: true
        }, {
            id: 'menu-print-documents',
            label: 'Print Documents',
            route: 'admin/print-documents',
            claimProgram: 'AdminPrintDocuments',
            mobile: false, desktop: true
        }, {
            id: 'menu-system-parameters',
            label: 'System Parameters',
            route: 'admin/system-parameters',
            claimProgram: 'AdminSystemParameters',
            mobile: false, desktop: true
        }, {
            id: 'menu-dealer-programs',
            label: 'Dealer Programs',
            route: 'admin/dealer-programs',
            claimProgram: 'AdminSystemParameters',
            mobile: false, desktop: true
        }, {
            id: 'menu-external-integrations',
            label: 'External Integrations',
            route: 'admin/external-integrations',
            claimProgram: 'AdminExternalIntegrations',
            mobile: false, desktop: true
        }]
    }
];
