import { Component, OnInit, OnDestroy } from '@angular/core';
import { CartService } from '../cart.service';
import { Cart } from '../cart.interface';

@Component({
  selector: 'app-cart-nav',
  templateUrl: './cart-nav.component.html',
  styleUrls: ['./cart-nav.component.scss']
})
export class CartNavComponent implements OnInit, OnDestroy {

  numCartItems: number;

  pulse = false;

  showBadge = false;

  cartSubscription = null;

  constructor(
    private cartSvc: CartService
  ) { }

  ngOnInit() {

    if (this.cartSvc.getCartId()) {
      this.showBadge = true;
    }

    this.cartSubscription = this.cartSvc.Cart.subscribe((res: Cart) => {

      if (res) {

        if (res['ModelCartItems']) {
          this.showBadge = true;
          let numItems = 0;
          for (const item of res['ModelCartItems']) {
            if (item.ProductModel?.Type !== 3) {
              numItems = numItems + item['ItemQty'];
            }
          }
          this.numCartItems = numItems;
          this.pulse = true;
        } else {
          this.numCartItems = null;
        }

        setTimeout(() => {
          this.pulse = false;
        }, 250);

      } else {
        this.showBadge = false;
        this.numCartItems = null;
      }

    });

  }

  ngOnDestroy() {

    if (this.cartSubscription) {
      this.cartSubscription.unsubscribe();
    }

  }

}
