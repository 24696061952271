import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { MultiselectOption } from '../interfaces/form.interface';


@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor() { }

  /**
   * Scroll to first field error
   */
  scrollToError(tgt: string | null = null): void {

    setTimeout(() => {

      const selector = tgt ? tgt : '.form-control.is-invalid',
        el = document.querySelector(selector);

      if (el) {
        el.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
      }

    }, 200);

  }

  /**
   * Get patch value object for reactive form
   * @param controls - reactive form controls
   * @param values
   */
  getPatchValue(controls: any, values: any): any {

    const patch: any = {};
    Object.keys(controls).forEach((ctrl: any) => {

      if (values[ctrl] && typeof values[ctrl] === 'object' && !Array.isArray(values[ctrl])) {
        patch[ctrl] = this.getPatchValue(controls[ctrl].controls, values[ctrl]);
      } else if (values[ctrl] || values[ctrl] === 0 || values[ctrl] === false) {
        patch[ctrl] = values[ctrl];
      }
    
    
    });

    return patch;

  }

  /**
   * Convert empty strings to null for form payload
   * @param formValue
   */
  getPayloadEmptyToNull(formValue: any): any {

    const payload: any = {};

    Object.keys(formValue).forEach((prop: string) => {
      if (formValue[prop] === '') {
        formValue[prop] = null;
      }
      payload[prop] = formValue[prop];
    });

    return payload;

  }

  /**
   * Prepare payload for api request
   * Trims strings
   * Convert empty strings to null if
   * @param payload
   * @param emptyToNull - Convert empty strings to null
   */
  preparePayload(payload: any, emptyToNull: boolean = false): any {

    Object.keys(payload).forEach((prop: string) => {

      if (typeof payload[prop] === 'string') {
        payload[prop] = payload[prop].trim();
        if (emptyToNull && payload[prop] === '') {
          payload[prop] = null;
        }
      }
      else if (typeof payload[prop] === 'object' && payload[prop] !instanceof Date && !Array.isArray(payload[prop])) {
        payload[prop] = this.preparePayload(payload[prop]);
      }

    });

    return payload;

  }

  /**
   * Prepare payload for http request
   * @param values 
   * @param emptyToNull 
   * @returns 
   */
  prepareRequestPayload(values: any, emptyToNull: boolean = false): any {

    // copy the values 
    let payload = JSON.parse(JSON.stringify(values));

    // loop through all of the values
    Object.keys(values).forEach((prop) => {

      // if value is string
      if (typeof values[prop] === 'string') {
        payload[prop] = payload[prop].trim();
        // convert empty strings to null values
        if (emptyToNull && payload[prop] === '') {
          payload[prop] = null;
        }  
      }

      // if an array
      else if (Array.isArray(values[prop])) {
        payload[prop] = this.prepareRequestPayload(values[prop], emptyToNull);
      }
      
      // if is an object but not null, undefined or a date
      else if (typeof values[prop] === 'object' && values[prop] !== null && values[prop] !== undefined && typeof values[prop].getMonth !== 'function') {
        payload[prop] = this.prepareRequestPayload(values[prop], emptyToNull);
      }

      else {
        payload[prop] = values[prop];
      }

    });

    return payload;

  }


  /**
   * Convert empty strings to null for form payload
   * @param values
   */
  getObjectEmptyToNull(values: any): any {

    const obj: any = {};

    Object.keys(values).forEach((prop: string) => {

      if (values[prop] && typeof values[prop] === 'object') {
        obj[prop] = this.getPayloadEmptyToNull(values[prop]);
      } else if (values[prop] === '') {
        obj[prop] = null;
      } else {
        obj[prop] = values[prop];
      }

      obj[prop] = values[prop]

    });

    return obj;

  }


  /**
   * Process api error responses for PUT and POST
   * @param err
   */
  processFormErrors(err: any) {

    const errors: any = {
      formErrors: null,
      fieldErrors: null
    };

    let hasErrors = false;

    if (err['error'] && err['error']['FieldErrors']) {

      errors.fieldErrors = {};

      for (const error of err['error']['FieldErrors']) {

        const errKey = error['Field'];

        const leftBracketIndex = errKey.indexOf('[');

        if (leftBracketIndex > -1) {

          const rightBracketIndex = errKey.indexOf(']');
          const key = errKey.substr(0, leftBracketIndex);
          const index = errKey.substring(leftBracketIndex + 1, rightBracketIndex);
          const field = errKey.substr(rightBracketIndex + 2);

          if (key) {

            if (!errors.fieldErrors[key]) {
              errors.fieldErrors[key] = {};
            }
  
            errors.fieldErrors[key][index] = {};
            errors.fieldErrors[key][index][field] = error['Message'];
          
          } else {

            if (!errors.fieldErrors[index]) {
              errors.fieldErrors[index] = {};
            } 

            errors.fieldErrors[index][field] = error.Message;

          }

        } else {
          errors.fieldErrors[errKey] = error['Message'];
        }

      }

      hasErrors = true;

    }

    if (err['error'] && err['error']['Errors']) {
      errors.formErrors = err['error']['Errors'];
      hasErrors = true;
    }

    if (!hasErrors) {
      errors.formErrors = [{Message: 'There was a problem saving the item.'}];
    }

    return errors;

  }

  scrollToTop(tgt = null): void {

    let el: any;
    if (tgt) {
      el = document.querySelector(tgt);
    } else {
      el = window;
    }

    el.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});

  }

  /**
   * Prepare multiselect options for multiselect component
   * @param res
   * @param idKey
   * @param labelKey
   * @param sortByLabel
   */
  prepareMultiselectOptions(res: any, idKey: string, labelKey: string, labelKey2?: string, sortByLabel?: boolean): MultiselectOption[] {

    const records: MultiselectOption[] = [];

    const data = Array.isArray(res) ? res : res.Data;

    data.forEach((record: any) => {

        const opt = {
            label: record[labelKey],
            value: record[idKey]
        };

        if (labelKey2) {
            opt.label = `${opt.label} - ${record[labelKey2]}`;
        }

        records.push(opt);

    });

    return sortByLabel ? records.sort((a, b) => ('' + a.label).localeCompare(b.label)) : records;

  }

  /**
   * Creates a download link and triggers a click event
   * @param res
   * @param filename
   */
  downloadGeneratedPdf(data: any, filename: string): void {
    this.downloadGeneratedFile(data, filename, 'pdf');
  }

  /**
   * Creates a download link and triggers a click event
   * @param res
   * @param filename
   */
  downloadGeneratedFile(data: any, filename: string, fileExt: string): void {

    const link = document.createElement('a');

    const url = URL.createObjectURL(data);
    link.setAttribute('href', url);
    link.setAttribute('download', `${filename}.${fileExt}`);
    link.setAttribute('target', '_blank');
    link.setAttribute('rel', 'noreferrer');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  }

  
  /**
   * Clear all items from form array
   * @param arrayName
   */
  clearFormArray(formArray: UntypedFormArray): void {

    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }

  }

    /**
   * Validate if start date is not later than end date
   * @param from
   * @param to
   */
   validateDateRange(_form: UntypedFormGroup, from: string, to: string): any {

    return (form: UntypedFormGroup): {[key: string]: any} => {
    
      const f = form.controls[from].value,
        t = form.controls[to].value;

      if (f > t && f && t) {
        return {
          dates: `Start date can't be before end date`
        };
      }
    
      return {};
    
    }

  }

}
