import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesDialogService {

  openDialog: EventEmitter<any> = new EventEmitter();
  proceed: EventEmitter<any> = new EventEmitter();

  constructor() { }

  open(): void {
    this.openDialog.emit({open: true});
  }

  action(res: any): void {
    this.proceed.emit(res.confirm);
  }

}
