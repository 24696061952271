import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// Material UI
import { MatSidenavModule } from '@angular/material/sidenav';
// import { OverlayModule } from '@angular/cdk/overlay';

// App modules/components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ToastMessageModule } from './widgets/toast-message/toast-message.module';
import { DeleteRecordDialogComponent } from './widgets/records/delete-record-dialog/delete-record-dialog.component';

// Services
import { AuthInterceptor } from './services/auth.interceptor';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { CartNavModule } from './cart/cart-nav/cart-nav.module';
import { UnsavedChangesDialogModule } from './widgets/unsaved-changes-dialog/unsaved-changes-dialog.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GlobalErrorHandler } from './global-error-handler';
import { SideBarNavComponent } from './side-bar-nav/side-bar-nav.component';
import { DeleteRecordDialogMobileComponent } from './widgets/records/delete-record-dialog-mobile/delete-record-dialog-mobile.component';
import { ContactSupportDialogComponent } from './support/contact-support-dialog/contact-support-dialog.component';
import { SessionExpiredConfirmationDialogComponent } from './session/session-expired-confirmation-dialog/session-expired-confirmation-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from './widgets/confirmation/confirmation-dialog/confirmation-dialog.component';
@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        DeleteRecordDialogComponent,
        DeleteRecordDialogMobileComponent,
        SessionExpiredConfirmationDialogComponent,
        SideBarNavComponent,
        ContactSupportDialogComponent,
        ConfirmationDialogComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CartNavModule,
        MatButtonModule,
        MatSidenavModule,
        MatTooltipModule,
        HttpClientModule,
        MatRippleModule,
        ToastMessageModule,
        UnsavedChangesDialogModule,
        AppRoutingModule,
        MatDialogModule
    ],
    providers: [{
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }, {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        }],
    bootstrap: [AppComponent]
})
export class AppModule { }
