import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DatastorageService {

  private data: any = {};

  constructor() { }

  /**
   * get data from data storage
   * @param {string} key
   * @returns {any}
   */
  getItem(key: string): any {
    const item: any = this.data[key];
    return item || null;
  }

  /**
   * set item in local storage
   * @param {string} key
   * @param {any} value
   */
  setItem(key: string, value: any): void {
    this.data[key] = value;
  }

  /**
   * remove item from local storage
   * @param {string} key
   */
  removeItem(key: string): void {
    if (this.data[key]) {
      delete this.data[key];
    }
  }

  /**
   * remove all items from local storage
   */
  removeAll(): void {
    this.data = {};
  }

}
