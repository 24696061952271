import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class LocalStorageService {

  private primaryKey = environment.lsKey;

  constructor() { }

  /**
   * get data from local storage
   * @param {string} key
   * @returns {any}
   */
  getItem(key: string): any {

    const item = localStorage.getItem(this.primaryKey);

    if (item) {

      const data = JSON.parse(item);

      if (typeof data[key] === 'boolean' || data[key]) {
        return data[key];
      } else {
        return null;
      }

    } else {
      return null;
    }

  }

  /**
   * set item in local storage
   * @param {string} key
   * @param {any} value
   */
  setItem(key: string, value: any): void {

    const item = localStorage.getItem(this.primaryKey);
    const data = JSON.parse(item);

    if (data) {
      data[key] = value;
      localStorage.setItem(this.primaryKey, JSON.stringify(data));
    } else {
      const obj = {
        [key]: value
      };
      localStorage.setItem(this.primaryKey, JSON.stringify(obj));
    }

  }

  /**
   * remove item from local storage
   * @param {string} key
   */
  removeItem(key: string): void {

    const item = localStorage.getItem(this.primaryKey);

    if (item) {

      const data = JSON.parse(item);

      if (data[key] !== null || data[key] !== undefined) {
        delete data[key];
        localStorage.setItem(this.primaryKey, JSON.stringify(data));
      }

    }
  }

  /**
   * remove all items from local storage
   */
  removeAll(): void {
    localStorage.setItem(this.primaryKey, null);
  }

}
