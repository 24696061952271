import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  title?: string;
  message?: string;
  confirmBtnLabel?: string;
  cancelBtnLabel?: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {

    if ('title' in this.data) {
      this.title = this.data.title;
    }

    if ('message' in this.data) {
      this.message = this.data.message;
    }

    this.confirmBtnLabel = this.data.confirmBtnLabel;
    this.cancelBtnLabel = this.data.cancelBtnLabel;

  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

}
