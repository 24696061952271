import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-contact-support-dialog',
  templateUrl: './contact-support-dialog.component.html',
  styleUrls: ['./contact-support-dialog.component.scss']
})
export class ContactSupportDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ContactSupportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

  /**
   * Handle cancel button event
   */
  onCancel(): void {
    this.dialogRef.close({confirm: false});
  }

}
