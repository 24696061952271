import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnsavedChangesDialogComponent } from './unsaved-changes-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { UnsavedChangesDialogContentComponent } from './unsaved-changes-dialog-content/unsaved-changes-dialog-content.component';



@NgModule({
    declarations: [UnsavedChangesDialogComponent, UnsavedChangesDialogContentComponent],
    exports: [UnsavedChangesDialogComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule
    ]
})
export class UnsavedChangesDialogModule { }
