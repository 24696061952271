import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';
import { AuthService } from '../services/auth.service';
import { LocalStorageService } from '../services/localstorage.service';
import { ContactSupportDialogComponent } from '../support/contact-support-dialog/contact-support-dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  showEnvHeader: boolean = true;

  @Output() menuStateChange: EventEmitter<any> = new EventEmitter<any>();

  // data
  name: string;
  user: object;
  env: string;

  showEnv: Observable<boolean>;
  envBarColors: any;
  collapseBar: boolean = false;


  constructor (
    private authSvc: AuthService,
    private envSvc: EnvironmentService,
    private lsSvc: LocalStorageService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {

    const user = this.lsSvc.getItem('user');
    const fName = user.firstName;
    let lInitial = user.lastName.charAt(0);

    if (user.lastName.length > 1) {
      lInitial = `${lInitial}.`;
    }

    this.name = `${fName} ${lInitial}`;

    this.env = this.envSvc.getEnvironment();
    this.showEnvHeader = this.lsSvc.getItem('showEnv');
    this.showEnv = this.envSvc.showEnvironmentInUI$;

    this.envBarColors = {
      'local': this.env === 'Local',
      'qa': this.env === 'QA',
      'training': this.env === 'Training',
      'staging': this.env === 'Staging'
    };

    this.showEnv.subscribe(showEnv => {
      this.showEnvHeader = showEnv;
    });

    // start expiration timer based on due time and expiration time amount
    this.authSvc.handleSessionExpiration();
  }

  /**
   * Support Dialog
   */
  contactSupport(e: any): void {
    e.preventDefault();
    
    this.dialog.open(ContactSupportDialogComponent, {
      disableClose: false,
      position: {top: '60px'},
      autoFocus: false,
      restoreFocus: false,
      width: '758px'
    }); 
  }

  /**
   * Logout
   */
  logout(e: any): void {
    e.preventDefault();
    this.authSvc.logout(false);
  }

  /**
   * Toggle opening and closing the main menu
   */
  toggleMenu(): void {
    this.menuStateChange.emit();
  }

  onEnvHeaderClick() {
    this.collapseBar = true;

    // 1 second delay to give time for the collapse 
    // animation to complete before hiding the header.
    setTimeout(() => {
      this.envSvc.hideEnv();
    }, 1000);
  }
}
