import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  // client secret key for api
  public clientSecret = '609642b3-0eee-4254-9f8f-362cd00f7d9d';

  constructor() {
  }


}
