import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'session-expired-confirmation-dialog',
  templateUrl: './session-expired-confirmation-dialog.component.html',
  styleUrls: ['./session-expired-confirmation-dialog.component.scss']
})
export class SessionExpiredConfirmationDialogComponent implements OnInit {

  // params
  displayTimeRemaining;

  constructor(
    private utilsSvc: UtilitiesService,
    private authSvc: AuthService,
    public dialogRef: MatDialogRef<SessionExpiredConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    let intitialTimeRemaining = this.utilsSvc.msToTime(this.authSvc.warningToExpireTime);
    this.displayTimeRemaining = `${intitialTimeRemaining.minutes}:${intitialTimeRemaining.seconds}`

    this.authSvc.getSessionTimer().subscribe((ms) => {
      if (ms > 0) {
        let time = this.utilsSvc.msToTime(ms + 1000);
        this.displayTimeRemaining =  `${time.minutes}:${time.seconds}`
      }
      else this.displayTimeRemaining = '0';
    })
  }

  onConfirm(): void {
    this.dialogRef.close({
      confirm: true,
    });
  }

  onCancel(): void {
    this.dialogRef.close({
      confirm: false
    });
  }
}
