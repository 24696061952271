import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { KeyValueOption } from '../interfaces/key-value-option.interface';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor(
    private title: Title
  ) { }
  

  /**
   * Build query string from object
   * @param {object} params
   * @returns {string} - query string
   */
  getQueryString(params: any): string {

    const queryString = Object.keys(params)
      .map((key: any) => {

        if (typeof params[key] === 'object') {

          let str = '';
          params[key].forEach((item: any, i: any) => {
            if (i === 0) {
              str = `${key}=${item}`;
            } else {
              str = `${str}&${key}=${item}`;
            }
          });

          return str;

        } else {
          return key + '=' + params[key];
        }

      })
      .join('&');

      return `?${queryString}`;

  }

   /**
   * Get object from url query parameters
   * @return query
   */
  getQueryParams(): any {

    const query = document.location.search;

    if (!query) {
      return null;
    }

    const params: any = {};
    const pairs = (query[0] === '?' ? query.substr(1) : query).split('&');
    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=');
        params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }

    return params;

  }

  /**
   * Sort array alphabetically A to Z
   * @param items
   * @param key
   */
  sortAlphabetically(items: any[], key: string): any {

    return items.sort((a: any, b: any) => {

      if (a[key] < b[key]) {
        return -1;
      } else if (a[key] > b[key]) {
        return 1;
      }

      return 0;

    });

  }

  /**
   * Sort array alphabetically A to Z
   * @param items
   * @param key
   */
  sortArray(items: any[], key: string, direction: string = 'asc'): any {

    return items.sort((a: any, b: any) => {

      if (direction === 'asc') {

        if (a[key] < b[key]) {
          return -1;
        } else if (a[key] > b[key]) {
          return 1;
        }
        
      } else {

        if (a[key] > b[key]) {
          return -1;
        } else if (a[key] < b[key]) {
          return 1;
        }

      }

      return 0;

    });

  }

  /**
   * Capitalize all words in a string
   * @param str
   * @returns string
   */
  toTitleCase(str: string) {

    return str.replace(/\w\S*/g, (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });

  }

  /**
   * Convert key value arrays to object
   */
  keyValueArrayToObject(items: KeyValueOption[], labelKey: string = 'label', valueKey: string = 'value'): any {

    const obj: any = {};
    items.forEach((item: any) => {
      obj[item[valueKey]] = item[labelKey];
    });
    return obj;

  }

  /**
   * Update title from program and tab key
   * @param program
   * @param tab
   */
  updateTitleFromTab(program: string, tab: string): void {

    const titleParts = tab.split('-');
    let titleTabName = null;

    titleParts.forEach((part: string, key: any) => {
      titleParts[key] = part.charAt(0).toUpperCase() + part.slice(1);
    });

    titleTabName = titleParts.join(' ');

    this.title.setTitle(`${program} ${titleTabName} | PointCentric`);

  }

  arrayToCSV(list: any, field: any){
    return list.map(item => item[field]).join(', ')
  }

  arrayToKeyValue(data: any[], label: string, value: any): any {

    const obj: any = {};
    data.forEach((item: any) => {
      obj[item[value]] = item[label];
    });

    return obj;

  }

  /**
   * Get record by id
   * @param {object} records
   * @param {number} id
   */
  getRecordById(records: Array<any>, id: number) {

    return records.find((r: any) => {
      return r['Id'] === id;
    });

  }

  /**
   * Get record by key
   * @param records
   * @param key
   * @param match
   */
  getRecordByKey(records: any[], key: any, match: any) {

    return records.find((r: any) => {
      return r[key] === match;
    });

  }

  /**
   * Get current timestamp for filter query
   */
  getFilterDatetime(d?: any, hasTime: boolean = true): string {

    const date = d ? new Date(d) : new Date();

    const day = date.getDate();
    const dayStr = (day < 10) ? '0' + String(day) : String(day);

    const month = date.getMonth() + 1;
    const monthStr = (month < 10) ? '0' + String(month) : String(month);

    let datetime = `${date.getFullYear()}-${monthStr}-${dayStr}`;

    if (hasTime) {
      datetime += 'T00-00-00';
    }

    return datetime;

  }

  /**
   * Get today's date
   */
  get todaysDate(): any {

    const date = new Date();
    date.setHours(0, 0, 0, 0);

    return date;

  }

    /**
   * Get yesterday's date
   */
     get yesterdaysDate(): object {

      const date = new Date();
      date.setHours(0, 0, 0, 0);
      date.setDate(date.getDate() - 1)
  
      return date;
  
    }

  /**
   * Prepares search parameters for filter parameter for search endpoints
   * @param searchFields - array of filter items
   * @param searchText - search term to match
   * @param filter - additional filter data
   */
  getRecordListFilterParams(searchFields: string[], searchText: string, filter?: any): string {

    let query = '';

    if (searchText) {
      for (const i in searchFields) {
        if (searchFields.hasOwnProperty(i)) {
          let prepend = `~or~`;
          if (i === '0') {
            prepend = '';
          }
          query += `${prepend}${searchFields[i]}~startswith~'${searchText}'`;
        }
      }
    }

    if (query && filter) {
      filter = `${filter}~and~(${query})`;
    } else if (query) {
      filter = query;
    }

    return filter;

  }

  /**
   * Get last day of the month for specified date
   * @param date
   */
  getLastDayOfMonth(date: Date): Date {

    const month = date.getMonth();
    const year = date.getFullYear();

    return new Date(year, month + 1, 0)

  }

  /**
   * Move item to top of array
   * @param arr - array
   * @param prop - property to match against
   * @param value - value to match
   * @returns 
   */
  moveItemToTopOfArray(arr: any[], prop: string, value: any): any[] {

    const itemIndex = arr.findIndex((item: any) => {
      return item[prop] === value;
    });

    if (itemIndex) {
      let shiftItem = arr.splice(itemIndex, 1);
      arr.unshift(shiftItem[0])
    }

    return arr;
    
  }

  /**
   * Compare 2 objects to see if the match
   */
  objectsMatch(obj1: any, obj2: any): boolean {

    // Loop through properties in object 1
    for (const p in obj1) {

      if (obj1.hasOwnProperty(p)) {

        // Check property exists on both objects
        if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) {
          return false;
        }

        switch (typeof (obj1[p])) {

          // Deep compare objects
          case 'object':
            if (!this.objectsMatch(obj1[p], obj2[p])) {
              return false;
            }
            break;

          // Compare function code
          case 'function':
            if (typeof (obj2[p]) === 'undefined' || (p !== 'compare' && obj1[p].toString() !== obj2[p].toString())) {
              return false;
            }
            break;

          // Compare values
          default:
            if (obj1[p] !== obj2[p]) {
              return false;
            }
        }

      }

    }

    // Check object 2 for any extra properties
    for (let p in obj2) {
      if (typeof (obj1[p]) === 'undefined') {
        return false;
      }
    }

    return true;

  }

  Sum(items: any[], prop: string) {
    return items.reduce( function(a, b){
        return a + b[prop];
    }, 0);
  };

   
  GroupBy = (array, key) => {  
    return array.reduce((result, currentValue) => {  
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      ); 
      return result;
    }, {}); 
  };

  Unique(array, key) {
    return [...new Set(array.map(item => item[key]))];
  }

  // converts ms to time and returns object: { hours: ??, minutes: ??, seconds: ??}
  msToTime(duration: number): any {
    let seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

      
      let h = (hours < 10) ? "0" + hours : hours;
      let m = (minutes < 10) ? "0" + minutes : minutes;
      let s = (seconds < 10) ? "0" + seconds : seconds;

      let time = {
        hours: h,
        minutes: m,
        seconds: s
      }
      
    return time;
  }
}
